import { INewsItem } from '../../types/INews'

import news0Item1 from './../images/news/news0Item1.png'
import news0Item2 from './../images/news/news0Item2.png'
import news0Item3 from './../images/news/news0Item3.jpeg'
import news1Item1 from './../images/news/news1Item1.png'
import EducateTImage from './../images/news/educate-t.png'

import ReminderImage from './../images/news/reminder.png'
import Reminder1Image from './../images/news/reminder1.jpg'
import Reminder2Image from './../images/news/reminder2.jpg'

import CyberImage from './../images/news/cyber.png'
import Cyber2Image from './../images/news/cyber2.jpg'

import head5 from './../images/news/5/head.png'
import image5_1 from './../images/news/5/image1.jpg'
import image5_2 from './../images/news/5/image2.jpg'
import image5_3 from './../images/news/5/image3.jpg'
import image5_4 from './../images/news/5/image4.jpg'

import head6 from './../images/news/6/head.png'
import image6_1 from './../images/news/6/image1.jpg'

import styles from './../../pages/NewsItemPage/NewsItemPage.module.scss'
import classNamesBind from 'classnames/bind'

const cx = classNamesBind.bind(styles)

export const news: INewsItem[] = [
	{
		id: 0,
		status: 'Блог',
		title: 'История создания компании Secure-T',
		// secondTitle: 'История создания компании Secure-T',
		image: news0Item1,
		text: `
		Известно, что самым слабым звеном в системе безопасности компании является человек. И какая бы дорогая и непробиваемая защита ни была установлена в компании, один невнимательный и неосведомлённый пользователь может всё разрушить. Согласно разным статистическим данным, совокупный объём данных, скомпрометированных в результате внутренних утечек в 2020 году, составил 11 млрд записей.
		<img src='${news0Item3}' alt='' />Чтобы обеспечить безопасность своих данных, многие компании начали обучать своих сотрудников основам информационной безопасности. Однако не всегда было понятно, как эффективно выстроить этот процесс. Со временем появились различные курсы по обучению, задача которых повысить осведомлённость сотрудников в области информационной безопасности.
		<br>Изучая конкурентов, мы пришли к выводу, что на российском рынке практически нет продуктов, способных адекватно противостоять современным угрозам. Поэтому было принято решение разработать собственный и уникальный продукт — Security Awareness Platforms, который избавляет компании от необходимости создания собственных курсов и закупки услуг по проверке уровня уязвимости сотрудников к методам социальной инженерии.
		<br>1 октября 2019 года был первый официальный рабочий день компании Secure-T, которая была создана двумя выпускниками Московского государственного университета им. М. В. Ломоносова — Шаховым Сергеем и Никишкиным Харитоном. До этого Сергей несколько лет работал специалистом по информационной безопасности в крупных российских компаниях, а Харитон занимался небольшим стартапом в сфере аналитике данных.
		<br>Первая версия Secure-T Awareness Platform вышла на рынок в июле 2020 года, которая объединяла в себе базовые курсы по информационной безопасности и ограниченный фишинговый модуль для проверки уровня уязвимости персонала. Продукт вызвал интерес у нескольких компаний, которые искали быстрый и эффективный способ улучшения уровня информационной безопасности своих сотрудников. В результате чего, под конец 2020 года Secure-T Awareness Platform насчитывала уже 5 000 пользователей.
		<br>За три года Secure-T успела зарекомендовать себя как надёжный партнёр в области информационной безопасности, что позволило привлечь внимание компаний из разных сфер деятельности. Благодаря этому за 2021 год мы подключили ещё 13 000 пользователей, а к концу 2022 года с помощью Secure-T Awareness Platform обучались уже 50 000 пользователей.
		<img src='${news0Item2}' alt='' />В результате активного применения нашей платформы компании смогли повысить уровень безопасности среди сотрудников. А некоторым клиентам даже удалось предотвратить потенциальные угрозы, которые были обнаружены после проверки уязвимости персонала.
		<br>В наше время информационные технологии играют ключевую роль в развитии бизнеса и общества. Поэтому мы делаем наш продукт доступным и понятным для всех пользователей, независимо от их уровня компетентности в области информационных технологий. Мы прислушиваемся к пожеланиям наших клиентов и реагируем на их потребности, чтобы сделать продукт ещё более полезным.
		`,
	},
	{
		id: 1,
		status: 'Новость',
		title: 'A1 и Secure-T запустили совместный сервис в Республике Беларусь!',
		// secondTitle: 'История создания компании Secure-T',
		image: news1Item1,
		text: `
		Мы рады объявить о <a href='https://a1data.by/news/novaya-platforma-security-awareness-ot-a1-investitsiya-v-razvitie-sotrudnikov-v-oblasti-informatsion/' target="_blank">запуске нашего сервиса</a> в партнерстве с инновационным лидером в сфере телекоммуникаций — A1! Платформа «Security Awareness» — это уникальное решение, которое призвано укрепить кибербезопасность вашего бизнеса. Это не просто сервис, это инвестиция в безопасное будущее вашей компании.

		<b>Что включено в «Security Awareness»:</b>
		• Базовые курсы по кибербезопасности.
		• Тестирование и аналитика.
		• Модуль для имитации фишинговых рассылок.
		
		<b>Особенности сервиса:</b>
		На платформе «Security Awareness» каждый заказчик может самостоятельно управлять образовательным процессом: создавать и редактировать текущие курсы, а также шаблоны фишинга. Вы можете формировать группы пользователей, настраивая индивидуальные правила для них, регулировать периодичность обучения по выбранным курсам и частоту фишинговых рассылок в соответствии с внутренней политикой безопасности компании.
		
		<b>Индивидуальные решения под любые потребности:</b>
		• <b>Security Awareness:</b> Обучение и фишинговые рассылки.
		• <b>Security Awareness Phishing:</b> Только фишинговые рассылки.
		• <b>Security Awareness Phishing Light:</b> Разовая фишинговая рассылка.
		
		<b>Безопасность превыше всего:</b>
		Платформа размещена в аттестованном сегменте дата-центре A1 класса TIER III, который является одним из крупнейших ЦОД на территории Республики Беларусь и отвечает высоким требованиям безопасности с уровнем доступности сервисов 99,982%.
		
		<b>Узнайте больше: </b>
		С подробностями нового сервиса можно ознакомиться по <a href='https://a1data.by/secure-t/' target="_blank">ссылке</a>.`,
	},
	{
		id: 2,
		status: 'Онлайн платформа',
		title:
			'Представляем Educate-T: Новые горизонты корпоративного обучения информационной безопасности и цифровой грамотности',
		// secondTitle: 'История создания компании Secure-T',
		image: EducateTImage,
		text: `<b>Представляем Educate-T: Новые горизонты корпоративного обучения информационной безопасности и цифровой грамотности</b>

	Мы рады объявить о полноценном запуске Educate-T - долгожданного дополнения к нашей образовательной платформе <a href='https://secure-t.ru/' target="_blank">Secure-T</a>.

	<a href='https://educate-t.ru/' target="_blank">Educate-T</a> предлагает к приобретению широкий набор продвинутых курсов по информационной безопасности, от компаний лидеров рынка.

	<b>С Educate-T можно:</b>

	1. Расширить базу знаний в своей компании. Погрузитесь глубже в сложные темы ИБ и ИТ с помощью продвинутых курсов, охватывающих самые разные темы: от кибербезопасности до языков программирования и анализа данных.
	2. Обучаться по программе передовых компаний и вузов. Контент от ведущих специалистов, известных своим опытом. Большинство курсов ориентировано на практические знания, которые можно напрямую применить к проектам и инициативам вашей организации.

	<b>Интеграция с Secure-T</b>

	В случае наличия активной подписки на платформе <a href='https://secure-t.ru/' target="_blank">Secure-T</a>, при покупке курса на Educate-T он автоматически добавится в ваш личный кабинет.

	<b>Доступные курсы</b>

	На нашей платформе вы сможете найти курсы от таких организаций, как Positive Technologies, CyberED, МФТИ Физтех, КОД ИБ Академия и конечно же Secure-T. В настоящий момент бесплатно доступны: “Кибербезопасность: первое погружение” от Positive Technologies (~1,5ч) и “Профессия — Белый Хакер” от CyberED (~9ч).

	<b>Сделайте следующий шаг</b>

	Готовы вывести обучение в вашей организации на новый уровень? Посетите <a href='https://educate-t.ru/' target="_blank">Educate-T</a> сегодня, чтобы ознакомиться с доступными материалами!`,
	},
	{
		id: 3,
		status: 'Новость',
		title: 'Команда Secure-T подготовила памятку по обеспечению информационной безопасности.',
		image: ReminderImage,
		isWide: true,
		text: `
		<div class="${cx('textWithImage')}">
			<div class="${cx('block1', 'block')}">
				<div>Сейчас киберугрозы становятся всё более изощрёнными, и потому каждый человек и организация должны быть осведомлены о рисках и методах их минимизации. <br/> 
				В связи с этим команда Secure-T подготовила подробную памятку по информационной безопасности. Этот материал создан, чтобы помочь распознавать и предотвращать распространённые киберугрозы, а также обеспечивать безопасность данных.</div>
				<img src='${Reminder1Image}' alt='' />
			</div>
			<div class="${cx('block2', 'block')}">
				<img src='${Reminder2Image}' alt='' align="left" />
				<div><b>Данная памятка содержит советы и рекомендации по таким темам, как:</b>
					<ul>
						<li>создание надёжных паролей,</li>
						<li>вычисление фишинга,</li>
						<li>подозрительные признаки вредоносных программ,</li>
						<li>безопасное использование мессенджеров и многое другое.</li>
					</ul>
					Таким образом, инструкция поможет наладить систему защиты как личной, так и корпоративной информации. Помните: регулярное обновление данных и обучение персонала могут обеспечить высокий уровень информационной безопасности. <b>Документ в формате PDF уже доступен для скачивания <a href='https://disk.yandex.ru/i/hYlRf0r6rnEQqg' target="_blank">по ссылке</a></b> <br/>
					Всю <b>актуальную информацию</b> о работе Secure-T и интересные новости вы сможете найти на <a href="https://t.me/Secure_Insights" target="_blank">официальном канале</a>
				</div>
			</div>
		</div>
		`,
	},
	{
		id: 4,
		status: 'Новость',
		title: 'Компания Secure-T разработала документ «Стратегия Киберкультуры для коммерческих организаций», направленный на повышение безопасности и укрепление культуры осведомлённости о киберугрозах внутри компаний.',
		image: CyberImage,
		isWide: true,
		text: `
		<div class="${cx('textWithImage')}">
			<div class="${cx('block2', 'block')}">
				<img src='${Cyber2Image}' alt='' />
				<div>В ноябре 2024 года Secure-T объявила о выходе <b>первой версии документа по стратегии киберкультуры.</b> Этот важный шаг призван помочь организациям адаптироваться к быстро меняющемуся цифровому миру, где киберугрозы становятся более сложными и разнообразными.<br/> 
				Данный документ содержит рекомендации по внедрению эффективных методов управления рисками, обучению сотрудников и созданию безопасной рабочей среды. Основное внимание уделяется активному вовлечению всего персонала в процесс обеспечения безопасности, что особенно важно при удалённой работе.<br/>
				Эксперты Secure-T уверены, что новая стратегия поможет организациям не только защитить данные, но и сформировать устойчивую киберкультуру, что, в свою очередь, станет гарантией успешного функционирования в современном бизнесе. <b>Документ уже доступен для скачивания <a href='https://disk.yandex.ru/i/9cc2Zq6WaxblTQ' target="_blank">по ссылке</a></b><br/>
				Всю <b>актуальную информацию</b> о работе Secure-T и интересные новости вы сможете найти на <a href="https://t.me/Secure_Insights" target="_blank">официальном канале</a></div>
			</div>
		</div>
		`,
	},
	{
		id: 5,
		status: 'Новость',
		title: 'Новинки Secure-T: Команда Secure-T выпустила новые обучающие игры, уроки по безопасной разработке и обновила популярные курсы компании!',
		image: head5,
		text: `
			Команда Secure-t подготовила для вас <b>3 новые увлекательные обучающие игры</b>, которые помогут освоить навыки кибербезопасности в интерактивной форме! Забудьте про скучные лекции – приготовьтесь к захватывающим заданиям, головоломкам и реальным сценариям, которые прокачают ваши знания и навыки.
			<div class="${cx('imageWithCaption')}"><img src='${image5_1}' alt='' /><span><b>“Хроники роботов: Угроза Зарбакса”</b></span></div>
			Первый курс <b>«Хроники роботов: Угроза Зарбакса»</b>, посвящённый различным видам мошенничества, погружает игроков в захватывающее киберпространство, в котором живёт главный герой – робот Нео. Игроку предстоит помочь главному герою в поисках своего друга и разгадать загадку появления вируса в городе.</br>
			Игра предлагает уникальное сочетание динамичного геймплея и образовательных элементов, позволяя игрокам изучать аспекты защиты информации. Погружаясь в увлекательные задания и решая задачи, игроки учатся основам безопасного поведения в цифровой среде.
			<div class="${cx('imageWithCaption')}"><img src='${image5_2}' alt='' /><span><b>“Онлайн-покупки: Как распознать мошенников”</b></span></div>
			Второй интерактивный курс <b>«Онлайн-покупки: Как распознать мошенников»</b> предлагает игрокам уникальную возможность узнать о безопасных покупках на маркетплейсах. Несмотря на то, что покупки на онлайн платформах стали привычными, мошенники всё равно находят способы обмануть покупателей.</br>
			На курсе мы разберём реальные примеры обмана — от фейковых продавцов до фишинговых рассылок от имени онлайн магазинов. Вы узнаете, как распознать опасные схемы и получите практические советы для успешного шоппинга, защиты своих данных и денег.
			<div class="${cx('imageWithCaption')}"><img src='${image5_3}' alt='' /><span><b>“Патруль Галактики. Глава 2: Белая планета”</b></span></div>
			Третий курс <b>«Патруль Галактики. Глава 2: Белая планета»</b> - вторая часть игры во вселенной Патруль Галактики. В этой игре вам предстоит перевоплотиться в капитана, которому было поручено найти преступников, стоящих за кибератакой. В рамках игры вам нужно будет исследовать загадочные просторы Белой планеты, взаимодействуя с разнообразными персонажами, выполняя их задания, и собирая улики, которые помогут в поисках преступников.</br>
			Эта игра не только даст возможность получить полезные знания и применить их, но погрузит вас в атмосферу научной фантастики.</br>
			Но это еще не всё! Мы также выпустили <b>уроки по безопасной разработке</b> и обновления для наших популярных курсов, которые сделают ваше обучение еще более эффективным и интересным.
			<div class="${cx('imageWithCaption')}"><img src='${image5_4}' alt='' /><span><b>Курсы по безопасной разработке</b></span></div>
			<b>Курсы по безопасной разработке</b>	 — это набор уроков, посвященных различным уязвимостям кода, с примерами на множестве языков программирования. В каждом уроке мы подробно разбираем уязвимости в контексте JavaScript, Java, Python, PHP, C#, C++, Go, и даже рассматриваем вопросы безопасности в ИИ и Kubernetes. Мы показываем, как проявляются уязвимости и, самое главное, как их устранить.</br>
			В системе теперь доступно более 50 обучающих материалов по безопасной разработке, которые покрывают все эти языки и технологии. Наши курсы помогут вам писать безопасный и надежный код, защищая ваши проекты от потенциальных угроз.</br>
			Это лишь часть того, что мы готовим для вас в этом году! Начните свой путь в мир кибербезопасности с нашими обучающими играми. Подробнее ознакомиться с курсами и играми можно на платформе!</br>
			Всю <b>актуальную информацию</b> о работе Secure-T и интересные новости вы сможете найти на <a href="https://t.me/Secure_Insights" target="_blank">официальном канале</a></div>
		`,
	},
	{
		id: 6,
		status: 'Новость',
		title: 'Компания Secure-T вошла в престижный рейтинг 100 перспективных стартапов 2024 года.',
		image: head6,
		text: `
			<b>Компания Secure-T</b>, специализирующаяся на разработке решений в области кибербезопасности, вновь подтвердила свой статус лидера отрасли, <b>войдя в престижный рейтинг 100 перспективных стартапов 2024 года.</b> Этот <a href="https://rb.ru/longread/rb-choice-100-2024/" target="_blank">список</a>, составленный авторитетными аналитиками технологий, включает наиболее инновационные и динамичные компании, обладающие значительным потенциалом для роста и влияния на свои отрасли. </br>
			Secure-T уникальна своим фокусом на защите данных и конфиденциальности пользователей, предлагая передовые решения для бизнеса, которые помогают защищать данные и инфраструктуру от киберугроз, включая разработку <b>платформы, на которой сотрудники могут обучаться кибербезопасности в цифровой среде.</b> С ее помощью можно создавать курсы для сотрудников, а также запускать учебные фишинговые рассылки и страницы. </br>
			<b>Команда Secure-T непрерывно работает над улучшением технологий</b>, чтобы отвечать самым высоким стандартам безопасности и адаптироваться к быстро меняющемуся миру угроз.</br>
			<b>Попадание в топ-100 стартапов</b> — это не только признание интеллектуального и трудового вклада команды, но и новый шаг на пути к расширению бизнеса. Secure-T стремится использовать этот импульс для дальнейшей разработки инновационных решений, обеспечивая безопасность своих клиентов в цифровом пространстве.
			<div class="${cx('imageWithCaption')}"><img src='${image6_1}' alt='' /></div>
			Всю <b>актуальную информацию</b> о работе Secure-T и интересные новости вы сможете найти на <a href="https://t.me/Secure_Insights" target="_blank">официальном канале</a></div>
		`,
	},
]